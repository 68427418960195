angular.module("app")
    .directive("tripReason", ['tripService', '$log', '$timeout', 'bookingService', 'Notification', function (tripService, $log, $timeout, bookingService, Notification) {
        return {
            restrict: "E",
            transclude: true,
            scope: {
                trip: "="
            },

            link: function (scope, element, attrs, controller) {


                function init() {
                    scope.reason = scope.trip.reason;
                }

                scope.getTemplateName = function () {
                    return '/static/templates/directive/trip-reason-edit.html';
                };


                scope.open = function () {
                    element.children('a').trigger('show');
                };

                scope.cancel = function () {
                    $log.debug(element[0].querySelector('.popover'));


                    $log.debug('Cancelled');
                    $timeout(function () {
                        element.children('a').trigger('hide');
                    }, 200);
                };

                scope.save = function (reason) {

                    scope.isSaving = true;

                    var trip = {
                        bookingReference: scope.trip.bookingReference,
                        tripReason: reason
                    };

                    bookingService.saveTripReason(scope.trip.organisationReference, trip)
                        .then(function (response) {
                            var result = response.data;
                            scope.reason = result.tripReason;
                            scope.trip.reason = result.tripReason;
                            $timeout(function () {
                                element.children('a').trigger('hide');
                            }, 200);
                            Notification.success({message: 'Trip reason saved', delay: 5000});
                        }, function (error) {
                            if (error && error.data) {
                                Notification.error({message: error.data, delay: 5000});
                            } else {
                                Notification.error({message: 'An error occured while save trip reason', delay: 5000});
                            }
                        }).finally(function () {
                        scope.isSaving = false;
                    });
                };

                init();
            },

            templateUrl: "/static/templates/directive/trip-reason.html"
        };
    }]);